<template>
  <div class="container-box">
    <div class="gx-header">
      <div class="bg-box">
        <img :src="pageData.productBgImg" />
      </div>
      <div class="header-content">
        <div class="title">
          <h3>{{ pageData.productTitle }}</h3>
        </div>
        <div class="line"></div>
        <div class="desc">
          <p>{{ pageData.productDetail }}</p>
        </div>
        <div class="img-box">
          <img :src="pageData.productImg" />
        </div>
      </div>
    </div>
    <div class="title-url">
      <div class="title">
        <span @click="goHome()">{{ $t('Home') }}</span>
        <span class="line"></span>
        <span @click="$router.push('/productCenter')">{{
          $t('productCenter')
        }}</span>
      </div>
    </div>
    <div class="info-panel-box">
      <div class="panel-header">
        <img src="@/assets/img/gxLogo.png" />
        <h3>{{ pageData.productTitle }}</h3>
      </div>
      <div class="panel-body">
        <div class="img-box">
          <img :src="pageData.productImg" />
          <div class="img-title">
            <p class="label">主要应用场景</p>
            <p>{{ pageData.scene }}</p>
          </div>
        </div>
        <div class="info-box">
          <div class="info-item">
            <div class="label">产品主要功能</div>
            <div class="content">
              <span v-for="(info, index) in pageData.majorFunctionList">{{
                info
              }}</span>
            </div>
          </div>
          <div class="info-item">
            <div class="label">技术特点及参数</div>
            <div class="content">
              <span v-for="(info, index) in pageData.featureList">{{
                info
              }}</span>
            </div>
          </div>
          <!-- <div class="info-item"></div> -->
        </div>
      </div>
      <div class="panel-footer">
        <p>{{ pageData.remarks }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductDetail',
  data() {
    return {
      pageData: ''
    }
  },
  mounted() {
    this.getProductDetail()
  },
  watch: {
    '$route.params.id'(newVal, oldVal) {
      if (newVal) {
        this.getProductDetail()
      }
    }
  },
  methods: {
    // 获取详情也数据
    getProductDetail() {
      let formData = {
        productId: this.$route.params.id
      }
      this.$axios
        .get('/officialweb/data/getProductInfoV2', formData)
        .then((res) => {
          if (res.code == 0 && res.data) {
            this.pageData = res.data
          }
        })
        .catch(() => {})
    },
    // 返回首页
    goHome() {
      this.$router.push({ path: '/' })
    }
  }
}
</script>

<style lang="less" scoped>
.container-box {
  width: 100%;
  .gx-header {
    width: 100%;
    height: 500px;
    overflow: hidden;
    position: relative;
    .bg-box {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: -1;
    }
    .header-content {
      width: @mainSize;
      height: 100%;
      text-align: left;
      margin: 0 auto;
      position: relative;
      color: #fff;
      padding-top: 150px;
      letter-spacing: 2px;

      .title {
        width: 65%;
        white-space: nowrap;
      }
      .line {
        width: 200px;
        height: 10px;
        margin: 20px 0px;
        background-color: @mainColor;
      }
      .desc {
        width: 65%;
        text-indent: 2em;
        color: @grayColor;
      }
      .img-box {
        width: 350px;
        height: 350px;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
  .info-panel-box {
    width: @mainSize;
    margin: 30px auto;
    color: #fff;
    background-color: @lightGrayColor;
    .panel-header {
      display: flex;
      align-items: center;
      height: 50px;
      background-color: @blueColor;
      img {
        margin: 0px 10px;
        height: 30px;
        object-fit: cover;
      }
      h3 {
        line-height: 30px;
        font-size: @titleSize;
      }
    }
    .panel-body {
      display: flex;
      width: 100%;
      height: 450px;
      padding: 10px;
      overflow: hidden;
      text-align: left;
      box-sizing: border-box;
      background-color: @lightGrayColor;
      .img-box {
        width: 480px;
        height: 430px;
        border-radius: 5px;
        box-sizing: border-box;
        overflow: hidden;
        background-color: #fff;
        transition: all 0.3s linear;
        &:hover {
          box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.2);
          img {
            transform: scale(1.1, 1.1);
          }
        }
        img {
          width: 100%;
          height: 300px;
          object-fit: cover;
          transition: all 0.3s linear;
        }
        .img-title {
          width: 100%;
          height: 130px;
          color: #fff;
          box-sizing: border-box;
          background-color: rgba(0, 0, 0, 0.6);
          p {
            letter-spacing: 1px;
            padding: 10px;
            font-size: 14px;
            &.label {
              color: @mainColor;
              font-size: 16px;
              font-weight: 700;
              background-color: rgba(0, 0, 0, 0.6);
            }
          }
        }
      }
      .info-box {
        margin-left: 10px;
        width: 100%;
        height: 430px;
        border-radius: 5px;
        background-color: #fff;
        .info-item {
          padding: 10px;
          .label {
            font-size: 16px;
            line-height: 40px;
            font-weight: 700;
            color: @mainColor;
            border-bottom: 1px dashed @blueColor;
            margin-bottom: 5px;
            &::before {
              content: '';
              border: 3px solid @blueColor;
              margin-right: 5px;
            }
          }
          .content {
            display: flex;
            flex-wrap: wrap;
            font-size: 14px;
            span {
              margin: 5px;
              padding: 5px;
              border-radius: 5px;
              background-color: @blueColor;
            }
          }
        }
      }
    }
    .panel-footer {
      width: 100%;
      height: 50px;
      font-size: 16px;
      // font-weight: 700;
      padding: 0 10px;
      text-align: left;
      color: #000;
      box-sizing: border-box;
      overflow: hidden;
      background-color: @lightGrayColor;
      p {
        padding: 0 10px;
        line-height: 40px;
        border-radius: 5px;
        background-color: #fff;
        margin-bottom: 10px;
      }
    }
  }
}
</style>
